<template>
  <div class="page">
    <form class="page-form" @click.prevent @submit.prevent="login(form)">
      <h3>Sign In</h3>

      <SvInput
          class="page-form-item"
          placeholder="Логин"
          v-model.trim="form.login"
          :error="error.login"
          @focus="error.login = ''"/>

      <SvInput
          class="page-form-item"
          type="password"
          placeholder="Пароль"
          v-model.trim="form.password"
          :error="error.password"
          @focus="error.password = ''"/>

      <SvButton
          border
          primary
          class="page-form-button"
          type="primary"
          @click="login(form)">Войти
      </SvButton>
    </form>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {ParseBackendErr} from "@/helpers";
import {SvButton, SvInput} from "@/components/default";

export default {
  name: "Auth",
  components: {
    SvButton,
    SvInput,
  },
  data() {
    return {
      form: {
        login: '',
        password: ''
      },
      error: {}
    }
  },
  methods: {
    ...mapActions("auth", [
      "signIn",
    ]),

    async login(data) {
      await this.signIn(data).then(() => {
        this.$router.push("/schools");
      }).catch((err) => {
        this.error = ParseBackendErr(err)

        if (this.error.credentials) {
          this.$message.error(this.error.credentials)
        }
      })
    }
  },
  beforeCreate() {
    // TODO: нужно избавиться от этой проверки.
    this.axios.post("/api/v1/auth/Check", {}).then((resp) => {
      if (resp.headers["x-admin"] === "true") {
        this.$router.push("/schools");
      }
    }).catch((err) => {
      console.error(err)
    })
  },
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;

  &-form {
    color: rgb(34, 34, 34);
    position: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 388px;
    padding: 34px;
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 26, 52, 0.16);
    background: var(--sv-theme-layout);

    &-item {
      height: 40px !important;
      margin-bottom: 25px;
    }

    &-button {
      margin-top: 30px;
      height: 40px !important;
    }
  }
}
</style>
